import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import doRequest, { imgBaseUrl } from "../utils/requestApi";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img
        src="./assets/images/nextem.svg"
        className="arrow_img_gallery"
        alt=""
      />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img
        src="./assets/images/prevem.svg"
        className="arrow_img_gallery"
        alt=""
      />
    </div>
  );
}

export default function Gallery() {
  const [galleryData, setGalleryData] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(false);

  const images = [
    "/assets/images/img-71.jpg",
    "/assets/images/img-72.jpg",
    "/assets/images/img-73.jpg",
    "/assets/images/img-74.jpg",
    "/assets/images/img-71.jpg",
    "/assets/images/img-72.jpg",
    "/assets/images/img-73.jpg",
    "/assets/images/img-74.jpg",
    "/assets/images/img-71.jpg",
    "/assets/images/img-72.jpg",
    "/assets/images/img-73.jpg",
    "/assets/images/img-74.jpg",
    "/assets/images/img-71.jpg",
    "/assets/images/img-72.jpg",
    "/assets/images/img-73.jpg",
    "/assets/images/img-74.jpg",
    // Add more image paths as needed
  ];

  var gallery = {
    dots: false,
    initialSlide: 0,
    centerPadding: "0px",
    infinite: true,
    autoplay: false,
    autoplaySpeed: 5000,
    centerMode: true,
    speed: 500,
    slidesToShow: 4,
    rows: 2,
    slidesToScroll: 4,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          centerPadding: "10px",
          centerMode: true,
          arrows: false,
          rows: 1,
        },
      },
    ],
    beforeChange: (current, next) => {
      if (next === galleryData.length / 2 - 1) {
        setIsLastSlide(true);
      } else {
        setIsLastSlide(false);
      }
    },
  };

  useEffect(() => {
    getGallerydata();
  }, []);

  useEffect(() => {
    if (isLastSlide && shouldLoadMore) {
      getGallerydata("loadmore");
    }
  }, [isLastSlide, shouldLoadMore]);

  const getGallerydata = async (loadmore) => {
    setLoading(true);
    let result = await doRequest({
      data: {},
      method: "GET",
      action: `gallery/getGallerylist?limit=${limit}&page=${page}`,
    });
    if (result && result.resultFlag == 1) {
      // if (loadmore) {
      //   setGalleryData([...galleryData, ...result.response]);
      // } else {
      //   setGalleryData(result.response);
      // }
      if (result.response.length.toString() >= limit) {
        setPage((prev) => prev + 1);
        setShouldLoadMore(true);
      } else {
        setShouldLoadMore(false);
      }
      let ary = [];

      for (let index = 0; index < result.response.length; index++) {
        const element = result.response[index];
        ary.push(imgBaseUrl + element.image_path);
      }
      if (loadmore) {
        setGalleryData([...galleryData, ...ary]);
      } else {
        setGalleryData(ary);
      }
      setLoading(false);
    }else {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 2 &&
      shouldLoadMore
    ) {
      getGallerydata("loadmore");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, shouldLoadMore]);
  return (
    <>
      {galleryData && galleryData.length > 0 && (
        <section className="gallery_section">
          <div className="container-fluid">
            <div className="inner_container">
              <h3 className="media_head">Gallery</h3>
              <ul className="gallery_list">
                {galleryData.map((image, index) => (
                  <li key={index}>
                    <div
                      className="gallery_img_div"
                      onClick={() => {
                        setLightboxOpen(true);
                        setPhotoIndex(index);
                      }}
                    >
                      <img
                        // src="/assets/images/img-71.jpg"
                        src={image}
                        className="gallery_img"
                        alt=""
                      />
                      <div className="overlay_gallery"></div>
                      <img
                        src="/assets/images/zoom.svg"
                        className="zoom_img"
                        alt=""
                      />
                    </div>
                  </li>
                ))}
              </ul>
              {loading && <div className="spinnerborder"></div>}
              {/* <div className="gallery_slider">
                <Slider {...gallery}>
                  {galleryData.map((image, index) => (
                    <div key={index}>
                      <div
                        className="gallery_img"
                        onClick={() => {
                          setLightboxOpen(true);
                          setPhotoIndex(index);
                        }}
                      >
                        <img
                          src={image}
                          className="gallery_img"
                          alt=""
                        />
                        <div className="overlay_gallery"></div>
                        <img
                          src="/assets/images/zoom.svg"
                          className="zoom_img"
                          alt=""
                        />
                      </div>
                    </div>
                  ))}
                   <div>
                                    <div className="gallery_img">
                                        <img src="/assets/images/img-72.jpg" className="gallery_img" alt="" />
                                        <div className="overlay_gallery"></div>
                                        <img src="/assets/images/zoom.svg" className="zoom_img" alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="gallery_img">
                                        <img src="/assets/images/img-73.jpg" className="gallery_img" alt="" />
                                        <div className="overlay_gallery"></div>
                                        <img src="/assets/images/zoom.svg" className="zoom_img" alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="gallery_img">
                                        <img src="/assets/images/img-74.jpg" className="gallery_img" alt="" />
                                        <div className="overlay_gallery"></div>
                                        <img src="/assets/images/zoom.svg" className="zoom_img" alt="" />
                                    </div>
                                </div>

                                <div>
                                    <div className="gallery_img">
                                        <img src="/assets/images/img-74.jpg" className="gallery_img" alt="" />
                                        <div className="overlay_gallery"></div>
                                        <img src="/assets/images/zoom.svg" className="zoom_img" alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="gallery_img">
                                        <img src="/assets/images/img-73.jpg" className="gallery_img" alt="" />
                                        <div className="overlay_gallery"></div>
                                        <img src="/assets/images/zoom.svg" className="zoom_img" alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="gallery_img">
                                        <img src="/assets/images/img-72.jpg" className="gallery_img" alt="" />
                                        <div className="overlay_gallery"></div>
                                        <img src="/assets/images/zoom.svg" className="zoom_img" alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="gallery_img">
                                        <img src="/assets/images/img-71.jpg" className="gallery_img" alt="" />
                                        <div className="overlay_gallery"></div>
                                        <img src="/assets/images/zoom.svg" className="zoom_img" alt="" />
                                    </div>
                                </div>


                                <div>
                                    <div className="gallery_img">
                                        <img src="/assets/images/img-71.jpg" className="gallery_img" alt="" />
                                        <div className="overlay_gallery"></div>
                                        <img src="/assets/images/zoom.svg" className="zoom_img" alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="gallery_img">
                                        <img src="/assets/images/img-72.jpg" className="gallery_img" alt="" />
                                        <div className="overlay_gallery"></div>
                                        <img src="/assets/images/zoom.svg" className="zoom_img" alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="gallery_img">
                                        <img src="/assets/images/img-73.jpg" className="gallery_img" alt="" />
                                        <div className="overlay_gallery"></div>
                                        <img src="/assets/images/zoom.svg" className="zoom_img" alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="gallery_img">
                                        <img src="/assets/images/img-74.jpg" className="gallery_img" alt="" />
                                        <div className="overlay_gallery"></div>
                                        <img src="/assets/images/zoom.svg" className="zoom_img" alt="" />
                                    </div>
                                </div>

                                <div>
                                    <div className="gallery_img">
                                        <img src="/assets/images/img-74.jpg" className="gallery_img" alt="" />
                                        <div className="overlay_gallery"></div>
                                        <img src="/assets/images/zoom.svg" className="zoom_img" alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="gallery_img">
                                        <img src="/assets/images/img-73.jpg" className="gallery_img" alt="" />
                                        <div className="overlay_gallery"></div>
                                        <img src="/assets/images/zoom.svg" className="zoom_img" alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="gallery_img">
                                        <img src="/assets/images/img-72.jpg" className="gallery_img" alt="" />
                                        <div className="overlay_gallery"></div>
                                        <img src="/assets/images/zoom.svg" className="zoom_img" alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="gallery_img">
                                        <img src="/assets/images/img-71.jpg" className="gallery_img" alt="" />
                                        <div className="overlay_gallery"></div>
                                        <img src="/assets/images/zoom.svg" className="zoom_img" alt="" />
                                    </div>
                                </div> 
                </Slider>
              </div> */}
            </div>
          </div>
        </section>
      )}
      {lightboxOpen && (
        <Lightbox
          mainSrc={galleryData[photoIndex]}
          nextSrc={galleryData[(photoIndex + 1) % galleryData.length]}
          prevSrc={
            galleryData[
              (photoIndex + galleryData.length - 1) % galleryData.length
            ]
          }
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + galleryData.length - 1) % galleryData.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % galleryData.length)
          }
          wrapperClassName="gallery_box"
        />
      )}
    </>
  );
}
