import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

export default function MediaSection() {
    return (
        <>
            <section className="media_section">
                <div className="container-fluid">
                    <div className="inner_container">
                        <div className="row">
                            <div className="col-sm-4 mediacard_pad">
                                <div className="media_card_bg">
                                    <Link to="/media" className="media_link">
                                        <div className="media_card_content">
                                            <img src="./assets/images/mediayellow.svg" className="twiiter_yellow" alt="" />
                                            <img src="./assets/images/mediablack.svg" className="twiiter_black" alt="" />
                                            <h4>Media</h4>
                                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p> */}
                                        </div>
                                    </Link>
                                </div>
                            </div>


                            <div className="col-sm-4 mediacard_pad">
                                <Link to="/twitter" className="media_link">
                                    <div className="media_card_bg">
                                        <div className="media_card_content">
                                            <img src="./assets/images/twiiteryellow.svg" className="twiiter_yellow" alt="" />
                                            <img src="./assets/images/twiiterblack.svg" className="twiiter_black" alt="" />
                                            <h4>Twitter</h4>
                                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>


                            <div className="col-sm-4 mediacard_pad">
                                <Link to="/instagram" className="media_link">
                                    <div className="media_card_bg">
                                        <div className="media_card_content">
                                            <img src="./assets/images/instayellow.svg" className="twiiter_yellow" alt="" />
                                            <img src="./assets/images/instablack.svg" className="twiiter_black" alt="" />
                                            <h4>Instagram</h4>
                                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}