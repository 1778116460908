import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function HomeBanner() {

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const images = [
        '/assets/images/collective.jpg',
        '/assets/images/collective2.jpg',

    ];

    var homebanner = {
        dots: true,
        initialSlide: 0,
        centerPadding: "0px",
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        centerMode: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,

    };

    return (
        <>
            <div className="banner_slider">
                <Slider {...homebanner}>
                {images.map((image, index) => (
                    <div key={index}>
                        <div className="banner_img_div" onClick={() => {
                            setLightboxOpen(true);
                            setPhotoIndex(index);
                        }}>
                            <img src={image} className="banner_img" alt="" />
                        </div>
                    </div>
                ))}
                    {/* <div>
                        <div className="banner_img_div">
                            <img src="/assets/images/collective2.jpg" className="banner_img" alt="" />
                        </div>
                    </div> */}
                </Slider>
            </div>
            {lightboxOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setLightboxOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                    }
                    wrapperClassName="light_box_banner"
                />
            )}
        </>
    )
}