import React, { useState, useEffect } from "react";


export default function Navbar() {
    return (
        <>
            <nav className="navbar navbar-expand-md navbar_bg">
                <div className="container-fluid">
                    <div className="inner_container inner_container_mob">
                        <div>
                            <div className="mobile_flex">
                                <a className="navbar-brand navlogo_cust" href="/">
                                    <img
                                        src="/assets/images/newlogo.jpg"
                                        className="bluelogo"
                                        alt=""
                                    />
                                </a>

                                <button
                                    className="navbar-toggler custom_btn"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapsibleNavbar"
                                >
                                    <span className="toggle_img"></span>
                                </button>
                            </div>

                            <div
                                className="collapse navbar-collapse custom_nabar"
                                id="collapsibleNavbar"
                            >
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#cover">
                                            Mann Ki Baat @ 100 
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#where">
                                            Order Now
                                        </a>
                                    </li>
                                    <li className="nav-item bluelogdesk">
                                        <a className="nav-link" href="/#cover">
                                            <img
                                                src="/assets/images/bluelogo.png"
                                                className="bluelogo"
                                                alt=""
                                            />
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#about">
                                            About{" "}
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#quote">
                                            The Journey
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}