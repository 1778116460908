import React from "react";

export default function Video({ dataList }) {
  return (
    <>
      <div className="row">
        {dataList?.length > 0 &&
          dataList?.map((item) => {
            return (
              <div key={item.id} className="col-sm-4">
                <div className="youtube_video">
                  {/* <iframe
                    src="https://www.youtube.com/embed/1-BY_eXiL0A?si=_3jfgA-JJPY1bYnN"
                    className="youtubevideo"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe> */}
                  <div
                    className="youtubevideo"
                    dangerouslySetInnerHTML={{
                      __html: item[`youtube_embed`],
                    }}
                  />
                  <div className="youtube_content">
                    <h6>{item.date}</h6>
                    <h5>{item.title}</h5>
                  </div>
                </div>
              </div>
            );
          })}

        {/* 
        <div className="col-sm-4">
          <div className="youtube_video">
            <iframe
              src="https://www.youtube.com/embed/1-BY_eXiL0A?si=_3jfgA-JJPY1bYnN"
              className="youtubevideo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <div className="youtube_content">
              <h6>Oct 23, 2023</h6>
              <h5>
                Compilation of Graphics on Serving the Poor, Honouring
                Marginalised
              </h5>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="youtube_video">
            <iframe
              src="https://www.youtube.com/embed/1-BY_eXiL0A?si=_3jfgA-JJPY1bYnN"
              className="youtubevideo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <div className="youtube_content">
              <h6>Oct 23, 2023</h6>
              <h5>
                Compilation of Graphics on Serving the Poor, Honouring
                Marginalised
              </h5>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="youtube_video">
            <iframe
              src="https://www.youtube.com/embed/1-BY_eXiL0A?si=_3jfgA-JJPY1bYnN"
              className="youtubevideo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <div className="youtube_content">
              <h6>Oct 23, 2023</h6>
              <h5>
                Compilation of Graphics on Serving the Poor, Honouring
                Marginalised
              </h5>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="youtube_video">
            <iframe
              src="https://www.youtube.com/embed/1-BY_eXiL0A?si=_3jfgA-JJPY1bYnN"
              className="youtubevideo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <div className="youtube_content">
              <h6>Oct 23, 2023</h6>
              <h5>
                Compilation of Graphics on Serving the Poor, Honouring
                Marginalised
              </h5>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="youtube_video">
            <iframe
              src="https://www.youtube.com/embed/1-BY_eXiL0A?si=_3jfgA-JJPY1bYnN"
              className="youtubevideo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <div className="youtube_content">
              <h6>Oct 23, 2023</h6>
              <h5>
                Compilation of Graphics on Serving the Poor, Honouring
                Marginalised
              </h5>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
