import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import VideoPlayer from "./VideoPlayer";
import axios from "axios";
import MediaSection from "./MediaSection";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import HomeBanner from "./HomeBanner";
import Gallery from "./Gallery";
import doRequest from "../utils/requestApi";
import VideoSection from "./VideoSection";
import VideoSlider from "./VideoSlider";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="./assets/images/nextem.svg" className="arrow_img" alt="" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="./assets/images/prevem.svg" className="arrow_img" alt="" />
    </div>
  );
}

export default function LandingPage() {
  const [fulldata, setFullData] = useState({});
  const [modiTweet, setModiTweet] = useState("");

  useEffect(() => {
    setModiTweet(
      '<blockquote class="twitter-tweet"><p lang="en" dir="ltr">I am happy that several people over the years have undertaken extensive research on <a href="https://twitter.com/hashtag/MannKiBaat?src=hash&amp;ref_src=twsrc%5Etfw">#MannKiBaat</a> and shared their insightful findings with people. Another such effort is the book ‘Igniting Collective Goodness’ by <a href="https://twitter.com/BlueKraft?ref_src=twsrc%5Etfw">@BlueKraft</a>, which chronicles how this programme has become a means…</p>&mdash; Narendra Modi (@narendramodi) <a href="https://twitter.com/narendramodi/status/1715681541697994798?ref_src=twsrc%5Etfw">October 21, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>'
    );
    const script = document.createElement("script");
    script.src = "/embed.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  var eminentslider = {
    dots: false,
    initialSlide: 0,
    centerPadding: "300px",
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          centerPadding: "150px",
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          centerPadding: "30px",
          arrows: false,
        },
      },
    ],
  };

  const handlePlayerReady = (player) => {
    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });
    player.on("play", () => {
      // try {
      //   window.gtag("event", "video_play_clicked", {
      //     platform: getDeviceType(),
      //     source: window.location.href,
      //   });
      // } catch (error) { }
    });
    player.on("pause", () => {
      // try {
      //   window.gtag("event", "video_pause_clicked", {
      //     platform: getDeviceType(),
      //     source: window.location.href,
      //   });
      // } catch (error) { }
    });
    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };
  const getDeviceType = () => {
    try {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
      }
      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        return "mobile";
      }
    } catch (er) {
      return "desktop";
    }
    return "desktop";
  };

  const getData = async () => {
    const res = await axios.get("data.json");
    // console.log(
    //   "getdata res ",
    //   res.data + " " + res.data.title + " - " + res.data.aboutthebook.image
    // );
    setFullData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {/* <nav className="navbar navbar-expand-md navbar_bg"> */}
      {/* <a className="navbar-brand" href="#">Navbar</a> */}

      {/* <VideoSlider /> */}
      <Navbar />

      <header className="homebanner_bg" id="cover">
        <div className="container-fluid">
          <div className="inner_container">
            <div className="row row_pad">
              <div className="col-sm-5">
                <div className="header_content">
                  <h2
                    dangerouslySetInnerHTML={{
                      __html:
                        Object.keys(fulldata).length > 0 && fulldata.title,
                    }}
                  ></h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        Object.keys(fulldata).length > 0 &&
                        fulldata.description,
                    }}
                  ></p>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="twitter_quote">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: modiTweet,
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <HomeBanner />
                {/* <div className="banner_img_div">
                  <img src={fulldata.image} className="banner_img" alt="" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="about_section" id="about">
        <div className="container">
          <div className="about_content">
            <h3>About</h3>
            {Object.keys(fulldata).length > 0 && (
              <div className="about_video">
                <VideoPlayer
                  autoplay={false}
                  onReady={handlePlayerReady}
                  id={"test"}
                  keyi={"test"}
                  key={"test"}
                  poster={fulldata.aboutthebook.poster}
                  videoUrl={fulldata.aboutthebook.videoUrl}
                />
                {/* <div className="overlay"></div>
              <img
                src="/assets/images/playicon.svg"
                className="playicon"
                alt=""
              /> */}
              </div>
            )}

            <h5
              dangerouslySetInnerHTML={{
                __html:
                  Object.keys(fulldata).length > 0 &&
                  fulldata.aboutthebook.highlights,
              }}
            ></h5>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  Object.keys(fulldata).length > 0 &&
                  fulldata.aboutthebook.description,
              }}
            ></p>
          </div>
        </div>
      </section>

      <MediaSection />

      <VideoSection />

      <section className="order_section" id="where">
        <div className="container-fluid">
          <div className="inner_container">
            <div className="row row_order_align">
              <div className="col-sm-6">
                <div className="order_content">
                  <h3>Get Your Copy Now!</h3>
                  {Object.keys(fulldata).length > 0 && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: fulldata.booklaunch.title,
                      }}
                    ></p>
                  )}
                  <div className="buylink_brd">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.amazon.in/Igniting-Collective-Goodness-Mann-Baat/dp/9357766553/ref=sr_1_7?keywords=mann+ki+baat&sr=8-74"
                      className="buy_link"
                      onClick={() => {
                        try {
                          let obj = {};
                          window.gtag("event", "Buy Book", obj);
                        } catch (error) {}
                      }}
                    >
                      Order Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <img
                  src="/assets/images/orderimg.png"
                  className="order_img"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="eminment_section" id="quote">
        <div className="container-fluid">
          <div className="eminment_content">
            {Object.keys(fulldata).length > 0 && (
              <h3>{fulldata.Quotes.title}</h3>
            )}
          </div>
          {Object.keys(fulldata).length > 0 && (
            <div className="eminent_slider">
              <Slider {...eminentslider}>
                {fulldata.Quotes.images.map((val, index) => {
                  return (
                    <div key={index}>
                      <div className="eminmet_content">
                        <div>
                          <img
                            src={val.poster}
                            className="eminent_ban"
                            alt=""
                          />
                        </div>
                        <div className="eminment_card">
                          <img
                            src="/assets/images/quote.svg"
                            className="quote_icon"
                            alt=""
                          />
                          <h4>{val.name}</h4>
                          <p
                            dangerouslySetInnerHTML={{ __html: val.quote }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* <div>
                  <div className="eminmet_content">
                    <div>
                      <img
                        src="/assets/images/img-18.jpg"
                        className="eminent_ban"
                        alt=""
                      />
                    </div>
                    <div className="eminment_card">
                      <img
                        src="/assets/images/quote.svg"
                        className="quote_icon"
                        alt=""
                      />
                      <h4>Nirmala Sitharaman</h4>
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo. Nemo
                        enim ipsam voluptat em quia voluptas sit aspernatur aut
                        odit aut fugit, sed quia consequuntur magni dolores{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="eminmet_content">
                    <div>
                      <img
                        src="/assets/images/img-16.jpg"
                        className="eminent_ban"
                        alt=""
                      />
                    </div>
                    <div className="eminment_card">
                      <img
                        src="/assets/images/quote.svg"
                        className="quote_icon"
                        alt=""
                      />
                      <h4>Nirmala Sitharaman</h4>
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo. Nemo
                        enim ipsam voluptat em quia voluptas sit aspernatur aut
                        odit aut fugit, sed quia consequuntur magni dolores{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="eminmet_content">
                    <div>
                      <img
                        src="/assets/images/img-18.jpg"
                        className="eminent_ban"
                        alt=""
                      />
                    </div>
                    <div className="eminment_card">
                      <img
                        src="/assets/images/quote.svg"
                        className="quote_icon"
                        alt=""
                      />
                      <h4>Nirmala Sitharaman</h4>
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo. Nemo
                        enim ipsam voluptat em quia voluptas sit aspernatur aut
                        odit aut fugit, sed quia consequuntur magni dolores{" "}
                      </p>
                    </div>
                  </div>
                </div> */}
              </Slider>
            </div>
          )}
        </div>
      </section>

      <Gallery />
    </>
  );
}
