import $ from "jquery";
// import request from "request";

var theRequest = null;
let ApiUrl = "";
if (window.location.host.indexOf("stage") !== -1) {
  ApiUrl = "https://stage.earshot.in/api/v1/cms";
} else if (window.location.host.indexOf("localhost") !== -1) {
  ApiUrl = "http://localhost:8080/v1/cms";
} else {
  ApiUrl = "https://earshot.in/api/v1/cms";
}

export let imgBaseUrl = 'https://nm-seva-photos.b-cdn.net/'
// export let WebUrl = "https://d1o9dundt2yz9q.cloudfront.net/v1";
// export let WebUrl = "https://apimother.narendramodi.in/v1";
export let localUrl = "http://localhost:8000/v1";

// export let WebUrl = "http://localhost:8000/v1";
export let WebUrl = "https://api.mkb100book.in/v1";
// export let WebUrl = "http://43.204.151.141:8000/v1";
const doRequest = async ({ data, method, action }, loader, button, cancel) => {
  // console.log(loader);
  let l = document.querySelector(".loader_bg");
  if (loader) {
    l.style.display = "block";
  }


  var settings = {
    url: WebUrl + "/" + action,
    method: method,
    timeout: 0,
    processData: false,
    mimeType: "application/json",
    contentType: false,
    data: data,
  };

  let response = await $.ajax(settings);
  l.style.display = "none";
  return response;



  // var options = {
  //   method: method ? method : "POST",
  //   url: WebUrl + "/" + action,
  //   // url: `${WebUrl}/${action}${method == 'GET' ? `?v=${Date.now()}` : ''}`,
  //   // qs: { action },
  //   headers: {
  //     "content-type": "application/json",
  //   },
  //   body: data,
  //   json: true,
  // };
  // if (button) {
  //   button.classList.add("disabled");
  // }
  // if (loader) {
  //   // try {
  //   document.getElementById("loaderdefault").style.display = "block";
  //   // } catch (error) {}
  // }

  // let response = await new Promise((resolve, reject) => {
  //   if (theRequest && cancel) {
  //     theRequest.abort();
  //   }
  //   theRequest = request(options, function (error, response, body) {
  //     // console.log('response ', response.statusCode);
  //     // console.log('body ', body);

  //     try {
  //       document.getElementById("loaderdefault").style.display = "none";
  //     } catch (error) { }

  //     if (error) {
  //       resolve(0);
  //     }

  //     if (response && response.statusCode === 403) {
  //       // console.log(response)
  //       // if (window.location.origin.indexOf("localhost") == -1) {
  //       //   window.location.replace("https://earshot.in/cms");
  //       //   return;
  //       // }
  //     }

  //     resolve(body);
  //   });
  //   // theRequest.pipe(parser);
  // });
  // console.log("response:", response)
  // setTimeout(() => {
  //   if (loader) {
  //     try {
  //       document.getElementById("loaderdefault").style.display = "none";
  //     } catch (error) { }
  //   }
  // }, 2000);

  // return response;
};

const ApiCall = async (data, action) => {
  var form = new FormData();

  Object.keys(data).forEach((key) => {
    form.append(key, data[key]);
  });
  var settings = {
    url: WebUrl + "/" + action,
    method: "POST",
    timeout: 0,
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    data: form,
  };

  let response = await $.ajax(settings);
  return response;
};

export default doRequest;

export { ApiCall };
