import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./Component/LandingPage";
import Media from "./Component/Media";
import Twitter from "./Component/Twitter";
import Instagram from "./Component/Instagram";


const AppRouter = () => {
    return (
        <>
            <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route path="/media" element={<Media />} />
                <Route path="/twitter" element={<Twitter />} />
                <Route path="/instagram" element={<Instagram />} />
            </Routes>
        </>
    )
}

export default AppRouter;