import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import doRequest from "../utils/requestApi";
import LoadMoreLoader from "./LoadMoreLoader";

export default function Instagram() {
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [loading, setLoading] = useState(false);

  const getDataList = async (loadmore) => {
    setLoading(true);
    let requestData = {
      data: {},
      method: "GET",
      action: `cms/home/getinstagramlist?limit=${limit}&page=${page}`,
    };
    let response = await doRequest(requestData);
    if (response?.resultFlag === 1 || response?.resultFlag === "1") {
      if (loadmore) {
        setDataList([...dataList, ...response.response]);
      } else {
        setDataList(response.response);
      }

      if (response.response.length.toString() >= limit) {
        setPage((prev) => prev + 1);
        setShouldLoadMore(true);
      } else {
        setShouldLoadMore(false);
      }
      const script = document.createElement("script");
      script.src = "/embed.js";
      script.async = true;
      document.body.appendChild(script);
      setLoading(false);
    } else {
      setLoading(false);
      response.message
        ? alert(response.message)
        : alert("Something went wrong");
    }
  };

  useEffect(() => {
    getDataList();
  }, []);

  const handleScroll = () => {
   
    if (
      window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight &&
      shouldLoadMore
    ) {
      getDataList("loadmore");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, shouldLoadMore]);
  return (
    <>
      <div className="media_header">
        <Navbar />
      </div>
      <section className="mediapage_section">
        <div className="container-fluid">
          <div className="inner_container">
            <h3 className="media_head">Instagram</h3>
            <div className="insta_div">
              {dataList?.length > 0 &&
                dataList?.map((item, index) => {
                  return (
                    <div className="insta_card" key={item.id}>
                      {/* <ul className="instapro_list">
                        <li>
                          <img
                            src="./assets/images/img-6.jpg"
                            className="insta_pro"
                            alt=""
                          />
                        </li>
                        <li className="insta_grow">
                          <h5>Amit Sharma</h5>
                          <h6>@amit_sharma</h6>
                        </li>
                        <li>
                          <a href="#">
                            <img
                              src="./assets/images/instagram.svg"
                              className="insta_pro_img"
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                      <img
                        src="./assets/images/img-46.jpg"
                        className="insta_banner"
                        alt=""
                      />
                      <p>
                        Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      </p> */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item[`msg`],
                        }}
                      />
                    </div>
                  );
                })}

              {/* <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-47.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>


                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-46.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>

                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-47.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>


                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-47.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>

                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-47.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>


                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-47.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>

                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-47.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>


                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-47.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>

                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-47.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>


                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-46.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>

                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-47.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>


                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-46.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>

                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-47.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>


                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-46.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>

                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-47.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>


                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-46.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>

                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-47.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>


                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-46.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>

                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-47.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>


                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-46.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>

                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-47.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>

                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-46.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>

                            <div className="insta_card">
                                <ul className="instapro_list">
                                    <li>
                                        <img src="./assets/images/img-6.jpg" className="insta_pro" alt="" />
                                    </li>
                                    <li className="insta_grow">
                                        <h5>Amit Sharma</h5>
                                        <h6>@amit_sharma</h6>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="./assets/images/instagram.svg" className="insta_pro_img" alt="" />
                                        </a>
                                    </li>
                                </ul>
                                <img src="./assets/images/img-47.jpg" className="insta_banner" alt="" />
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div> */}
            </div>
            {loading && <div className="spinnerborder"></div>}
          </div>
        </div>
      </section>
    </>
  );
}
