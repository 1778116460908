import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { imgBaseUrl } from "../utils/requestApi";
import VideoPlayer from "./VideoPlayer";
import videojs from "video.js";

export default function ImageSlider({ galleryData, index, close, videoData }) {
  const [currentSlide, setCurrentSlide] = useState(index);
  const [currPlayer, setCurrPlayer] = useState();
  const [playerAry, setPlayerAry] = useState([]);
  // console.log("index", index);
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={() => {
          onClick();
        }}
      >
        <img
          src="./assets/images/arrowright.svg"
          className="video_arrow"
          alt=""
        />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={() => {
          onClick();
        }}
      >
        <img
          src="./assets/images/arrowleft.svg"
          className="video_arrow"
          alt=""
        />
      </div>
    );
  }

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const upperimage = {
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: nav2,
    arrows: true,
    infinite: false,
    afterChange: (index) => {
      const videos = document.querySelectorAll("video");
      // console.log("videos are ", videos);
      for (let i = 0; i < videos.length; i++) {
        // console.log("videos are i is ",  );
        let dataId = videos[i].getAttribute("data-id");
        if (dataId != index) {
          if (!videos[i].paused) {
            videos[i].pause();
          }
        } else {
          videos[i].play();
        }
      }
    },
    dots: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const thumbimage = {
    slidesToShow: 10,
    swipeToSlide: true,
    focusOnSelect: true,
    className: "center",
    infinite: false,
    centerMode: true,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
          arrows: false,
        },
      },
    ],
  };

  const slider1Ref = useRef(null);
  const slider2Ref = useRef(null);

  // Function to set the current slide for the main image slider
  function setSlide1(index) {
    setCurrentSlide(index);
    if (slider1Ref.current) {
      if (currPlayer) {
        handlePlayerReady(currPlayer);
      }
      slider1Ref.current.slickGoTo(index);
    }
  }

  useEffect(() => {
    // Initially set the current slide to the 5th image (index 4)
    setSlide1(index);
  }, [index]);

  const handlePlayerReady = (player) => {
    console.log(player, "playerplayer..");
    // setPlayerAry([...playerAry, ...[player]]);
    // you can handle player events here
    player.on("waiting", () => {
      // console.log("player is waiting");
    });
    player.on("play", () => {
      // try {
      //   window.gtag("event", "video_play_clicked", {
      //     platform: getDeviceType(),
      //     source: window.location.href,
      //   });
      // } catch (error) { }
    });
    player.on("pause", () => {
      // try {
      //   window.gtag("event", "video_pause_clicked", {
      //     platform: getDeviceType(),
      //     source: window.location.href,
      //   });
      // } catch (error) { }
    });
    player.on("dispose", () => {
      // console.log("player will dispose");
    });
  };

  const handleCurrPlayer = (player) => {
    setCurrPlayer(player);
  };

  return (
    <>
      <div className="videoslider_bg">
        <div className="video_slider_pos">
          <h4 className="video_heading">Videos</h4>
          <div className="video_slider">
            <Slider {...upperimage} asNavFor={nav2} ref={slider1Ref}>
              {videoData?.length > 0 &&
                videoData?.map((item, i) => {
                  // console.log("video", item);
                  return (
                    <div key={i}>
                      <div className="videoslideer_div">
                        {/* <video className="show_video" controls width="400">
                          <source src={item} type="video/mp4" />
                        </video> */}
                        <VideoPlayer
                          autoplay={i == index ? true : false}
                          onReady={handlePlayerReady}
                          // id={`video_slider_${index}`}
                          id={`video_slider`}
                          keyi={"test_video_slider"}
                          // playerAry={playerAry}
                          key={"test_video_slider"}
                          poster={galleryData[i]}
                          videoUrl={item}
                          setCurrPlayer={handleCurrPlayer}
                          index={i}
                        />
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>

          <div className="video_slider_list">
            <Slider
              {...thumbimage}
              asNavFor={nav1}
              ref={(slider2) => setNav2(slider2)}
            >
              {galleryData?.length > 0 &&
                galleryData?.map((item, index) => {
                  return (
                    <div key={index}>
                      <img
                        onClick={() => setSlide1(index)}
                        src={item}
                        className="img_thumlist"
                        alt=""
                      />
                    </div>
                  );
                })}
            </Slider>
          </div>

          <button onClick={() => close()} className="close_btn">
            Close
          </button>
        </div>
      </div>
    </>
  );
}
