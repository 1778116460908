import React from "react";
import { base_url, formatDate } from "../../utils/helper";

export default function Digital({ dataList }) {
  return (
    <div className="row">
      {dataList?.length > 0 &&
        dataList?.map((item, index) => {
          return (
            <div className="col-sm-4" key={item.id}>
              <a
                href={item.redirection_url}
                rel="noreferrer"
                target="_blank"
                className="media_link"
              >
                <div className="media_card">
                  {item.image_path && (
                    <img
                      src={`${base_url}${item.image_path}`}
                      className="media_card_banner"
                      alt=""
                    />
                  )}
                  <div className="media_cardcontent">
                    <h4>{item.title}</h4>
                    <ul className="media_list">
                      <li>
                        <h6>{item.date && formatDate(item.date)}</h6>
                      </li>
                      <li>
                        {item.logo && (
                          <img
                            src={base_url + item.logo}
                            className=""
                            alt="logo"
                          />
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </a>
            </div>
          );
        })}
    </div>
  );
}
