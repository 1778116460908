import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import { base_url } from "../../utils/helper";
import ImageSlider from "./ImageSlider";

var settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  autoplay: true,
  easing: "linear",
  fade: true,
  // rows: 1,
  // respondTo:'window',
  variableWidth: false,
  accessibility: true,
};

export default function Print({ dataList }) {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState("");
  const [imageArr, setImageArr] = useState([]);

  useEffect(() => {
    if (dataList?.length > 0) {
      let arr = dataList?.map((item) => `${base_url}${item.image_path}`);
      setImageArr(arr);
    }
  }, [dataList]);

  const openLighboxHandler = (index) => {
    document.body.classList.add('slideroverflow');
    setLightboxOpen(true);
    setPhotoIndex(index);
  };

  const closeLighbox = () => {
    document.body.classList.remove('slideroverflow');
    setLightboxOpen(false);
  };
  return (
    <>
      <section className="gallery_section">
        <div className="container-fluid">
          <div className="inner_container">
            <h3 className="media_head">Gallery</h3>
            <ul className="gallery_list">
              {dataList?.length > 0 &&
                dataList?.map((image, index) => {
                  return (
                    <li key={index}>
                      <div
                        className="gallery_img_div"
                        onClick={() => {
                          openLighboxHandler(index);
                        }}
                      >
                        <img
                          src={base_url + image["image_path"]}
                          className="gallery_img"
                          alt=""
                        />
                        <div className="overlay_gallery"></div>
                        <img
                          src="/assets/images/zoom.svg"
                          className="zoom_img"
                          alt=""
                        />
                      </div>
                    </li>
                  );
                })}
            </ul>
            {/* {loading && <div className="spinnerborder"></div>} */}
          </div>
        </div>
      </section>

      {/* {lightboxOpen && (
        <Lightbox
          mainSrc={imageArr[photoIndex]}
          nextSrc={imageArr[(photoIndex + 1) % imageArr.length]}
          prevSrc={
            imageArr[(photoIndex + imageArr.length - 1) % imageArr.length]
          }
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + imageArr.length - 1) % imageArr.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imageArr.length)
          }
          wrapperClassName="gallery_box"
        />
      )} */}

      {lightboxOpen && (
        <ImageSlider
          close={closeLighbox}
          index={photoIndex}
          dataList={imageArr}
          
        />
      )}
    </>
  );
}
