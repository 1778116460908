import React, { useState, useEffect } from "react";
import VideoSectionList from "./VideoSectionList";


export default function VideoSection() {
    return (
        <>
            <section className="video_section_pad">
                <div className="container-fluid">
                    <div className="inner_container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="about_content">
                                    <h3>Videos</h3>
                                </div>
                            </div>
                        </div>
                        


                        <VideoSectionList />
                    </div>
                </div>
            </section>
        </>
    )
}