export const base_url = 'https://nm-seva-photos.b-cdn.net/'

export const formatDate = (inputDate) => {
    const [year, month, day] = inputDate.split('-');

    const date = new Date(year, month - 1, day); // Subtract 1 from the month because months are 0-indexed in JavaScript
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const formattedDate = `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;

    return formattedDate;
}
