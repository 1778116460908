import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

export default function ImageSlider({ dataList, index, close }) {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img
          src="./assets/images/arrowright.svg"
          className="video_arrow"
          alt=""
        />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img
          src="./assets/images/arrowleft.svg"
          className="video_arrow"
          alt=""
        />
      </div>
    );
  }

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const upperimage = {
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: nav2,
    arrows: true,
    infinite: false,
    dots: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const thumbimage = {
    slidesToShow: 10,
    swipeToSlide: true,
    focusOnSelect: true,
    infinite: false,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
          arrows: false,
        },
      },
    ],
  };

  const slider1Ref = useRef(null);
  const slider2Ref = useRef(null);

  // Function to set the current slide for the main image slider
  function setSlide1(index) {
    if (slider1Ref.current) {
      slider1Ref.current.slickGoTo(index);
    }
  }

  useEffect(() => {
    // Initially set the current slide to the 5th image (index 4)
    setSlide1(index);
  }, [index]);

  return (
    <>
      <div className="videoslider_bg">
        <div className="video_slider_pos">
          <h4 className="video_heading">Images</h4>
          <div className="video_slider">
            <Slider {...upperimage} asNavFor={nav2} ref={slider1Ref}>
              {dataList?.length > 0 &&
                dataList?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="videoslideer_div">
                        <img src={item} className="videoimg_active" alt="" />
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>

          <div className="video_slider_list">
            <Slider
              {...thumbimage}
              asNavFor={nav1}
              ref={(slider2) => setNav2(slider2)}
            >
              {dataList?.length > 0 &&
                dataList?.map((item, index) => {
                  return (
                    <div key={index}>
                      <img onClick={() => setSlide1(index)} src={item} className="img_thumlist" alt="" />
                    </div>
                  );
                })}
            </Slider>
          </div>

          <button onClick={() => close()} className="close_btn">
            Close
          </button>
        </div>
      </div>
    </>
  );
}
