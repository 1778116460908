import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import doRequest from "../utils/requestApi";
import { base_url, formatDate } from "../utils/helper";
import Gallery from "./Gallery";
import VideoSection from "./VideoSection";
import VideoSectionList from "./VideoSectionList";
import Digital from "./Media/Digital";
import Print from "./Media/Print";
import Video from "./Media/Video";

export default function Media() {
  const [dataList, setDataList] = useState([]);
  const [mediaType, setMediaType] = useState("digital");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [loading, setLoading] = useState(false);

  const getDataList = async (loadmore, type) => {
    setLoading(true);
    let requestData = {
      data: {},
      method: "GET",
      action: `media/getMedialist?limit=${limit}&page=${page}&type=${
        type ? type : mediaType
      }`,
    };
    let response = await doRequest(requestData);
    if (response?.resultFlag === 1 || response?.resultFlag === "1") {
      if (loadmore) {
        setDataList([...dataList, ...response.response]);
      } else {
        setDataList(response.response);
      }
      if (response.response.length.toString() >= limit) {
        setPage((prev) => prev + 1);
        setShouldLoadMore(true);
      } else {
        setShouldLoadMore(false);
      }
      setLoading(false);
    } else {
      setLoading(false);
      response.message
        ? alert(response.message)
        : alert("Something went wrong");
    }
  };

  useEffect(() => {
    getDataList();
  }, []);

  const handleScroll = () => {
    if (
      +(window.innerHeight + document.documentElement.scrollTop) >=
        +(document.documentElement.offsetHeight - 2) &&
      shouldLoadMore
    ) {
      getDataList("loadmore");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, shouldLoadMore]);
  return (
    <>
      <div className="media_header">
        <Navbar />
      </div>
      <section className="mediapage_section">
        <div className="container-fluid">
          <div className="inner_container">
            <div className="medi_flextab">
              <h3 className="media_head">Media</h3>

              <ul
                className="nav nav-tabs custom_tabs"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    onClick={() => {
                      setMediaType("digital");
                      getDataList("", "digital");
                    }}
                  >
                    Digital
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    onClick={() => {
                      setMediaType("print");
                      getDataList("", "print");
                    }}
                  >
                    Print
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#contact"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                    onClick={() => {
                      setMediaType("video");
                      getDataList("", "video");
                    }}
                  >
                    Videos
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                {mediaType === "digital" && <Digital dataList={dataList} />}
                {loading && <div className="spinnerborder"></div>}
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div className="galllery_media">
                  {/* <Gallery /> */}
                  {mediaType === "print" && <Print dataList={dataList} />}
                  {loading && <div className="spinnerborder"></div>}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                {mediaType === "video" && <Video dataList={dataList} />}
                {loading && <div className="spinnerborder"></div>}
                {/* <VideoSectionList /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
