import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import doRequest from "../utils/requestApi";

export default function Twitter() {
  const [tweetData, setTweetData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getTweetList();
  }, []);

  const getTweetList = async (loadmore) => {
    setLoading(true);
    let result = await doRequest({
      data: {},
      method: "GET",
      action: `cms/home/gettweetlist?limit=${limit}&page=${page}`,
    });
    if (result && result.resultFlag == 1) {
      setTweetData(result.response);
      if (loadmore) {
        setTweetData([...tweetData, ...result.response]);
      } else {
        setTweetData(result.response);
      }
      if (result.response.length >= limit) {
        setPage((prev) => prev + 1);
        setShouldLoadMore(true);
      } else {
        setShouldLoadMore(false);
      }
      //   setMediaList(props.data);
      const script = document.createElement("script");
      script.src = "/widgets.js";
      script.async = true;
      document.body.appendChild(script);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 2 &&
      shouldLoadMore
    ) {
      getTweetList("loadmore");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, shouldLoadMore]);
  return (
    <>
      <div className="media_header">
        <Navbar />
      </div>
      <section className="mediapage_section">
        <div className="container-fluid">
          <div className="inner_container">
            <h3 className="media_head">Twitter</h3>
            <div className="insta_div">
              {tweetData &&
                tweetData.length > 0 &&
                tweetData.map((media) => {
                  return (
                    <div key={media.id} className="insta_card">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: media[`msg`],
                        }}
                      />
                    </div>
                  );
                })}
            </div>
              {loading && <div className="spinnerborder"></div>}
          </div>
        </div>
      </section>
    </>
  );
}
