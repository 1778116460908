import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import doRequest, { imgBaseUrl } from "../utils/requestApi";
import VideoSlider from "./VideoSlider";
import { base_url } from "../utils/helper";

export default function VideoSectionList() {
  const [loading, setLoading] = useState(false);
  const [videoindex, setVideoindex] = useState("");
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [galleryData, setGalleryData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [selectedImage, setSelectedImage] = useState(null);
  const [video, setVedio] = useState(false);
  const [imageArr, setImageArr] = useState([]);
  const [videoArr, setVideoArr] = useState([]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src="./assets/images/nextem.svg" className="videoarrow" alt="" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src="./assets/images/prevem.svg" className="videoarrow" alt="" />
      </div>
    );
  }
  var videoslider = {
    dots: false,
    initialSlide: 0,

    infinite: false,
    autoplay: false,
    autoplaySpeed: 5000,
    // centerMode: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };
  useEffect(() => {
    getVideodata();
  }, []);

  const getVideodata = async (loadmore) => {
    setLoading(true);
    let requestData = {
      data: {},
      method: "GET",
      action: `homevideo/getVideolist?limit=${limit}&page=${page}`,
    };
    let response = await doRequest(requestData);
    if (response?.resultFlag === 1 || response?.resultFlag === "1") {
      if (loadmore) {
        setGalleryData([...galleryData, ...response.response]);
      } else {
        setGalleryData(response.response);
      }
      if (response.response.length.toString() >= limit) {
        setPage((prev) => prev + 1);
        setShouldLoadMore(true);
      } else {
        setShouldLoadMore(false);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (galleryData?.length > 0) {
      let arr = galleryData?.map((item) => `${base_url}${item.thumb}`);
      setImageArr(arr);
      // let vid_arr = galleryData?.map((item) => `${base_url}${item.m3u8}`);
      let vid_arr = galleryData?.map((item) => `${item.m3u8}`);
      setVideoArr(vid_arr);
    }
  }, [galleryData]);

  const openVideobox = (index) => {
    document.body.classList.add("slideroverflow");
    let video = document.getElementById("myVideo_test_html5_api");
    if (!video.paused) {
      video.pause();
    }
    setVedio(true);
    setVideoindex(index);
    // console.log("index is ", index);
  };

  const closeVideobox = () => {
    document.body.classList.remove("slideroverflow");
    setVedio(false);
  };

  return (
    <>
      <div className="videosection_slider">
        <Slider {...videoslider}>
          {galleryData?.length > 0 &&
            galleryData?.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className="vide_card"
                    onClick={() => {
                      openVideobox(index);
                    }}
                  >
                    <div className="video_thumb">
                      <img
                        key={index}
                        src={imgBaseUrl + item.thumb}
                        className="videbanner"
                        alt=""
                      />
                      <img
                        src="./assets/images/playblack.svg"
                        className="playblack_img"
                        alt=""
                      />
                      <div className="video_overlay"></div>
                    </div>
                    <div className="videthumb_con">
                      <h5>{item.title}</h5>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>

      {video && (
        <VideoSlider
          close={closeVideobox}
          index={videoindex}
          galleryData={imageArr}
          videoData={videoArr}
        />
      )}
    </>
  );
}
